import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]



  toggle(e) {
    var handle = e.target.tagName == "SPAN" ? e.target.parentNode : e.target;
    var content = this.getContentElementFor(handle);
    if(!content) return;
    handle.parentNode.classList.contains("open") ? this.close(content) : this.open(content);
  }

  open(content) {
    content.style.maxHeight = content.scrollHeight + "px";
    content.addEventListener("transitionend", function transition(e) {
      content.style.maxHeight = this.isOpen(content) ? "none" : null;
      content.removeEventListener("transitionend", transition);
    }.bind(this)); // {once: true} //ES6
    content.parentNode.classList.add("open");
    this.closeAll(content);
  }

  close(content) {
    content.style.maxHeight = content.scrollHeight + "px";
    setTimeout(function() {
      content.style.maxHeight = null;
    }, 50);
    content.parentNode.classList.remove("open");
  }

  closeAll(except=null) {
    this.contentTargets.forEach(function(el) {
      if(this.isOpen(el) && el != except) this.close(el);
    }.bind(this));
  }

  isOpen(element) {
    return element.parentNode.classList.contains("open");
  }

  getContentElementFor(handle) {
    //return this.contentTargets.find(el => el.parentNode == handle.parentNode); //ES6
    var content;
    this.contentTargets.forEach(function(el) {
      if(el.parentNode == handle.parentNode) return content = el;
    });
    return content;
  }
}

