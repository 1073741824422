import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["message"]

  connect() {
  }

  blink(e) {
    e.preventDefault()
    let _this = this
    _this.messageTarget.classList.add("blink")
    setTimeout(function(){_this.messageTarget.classList.remove("blink")}, 1500);
  }


}