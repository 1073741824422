import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "links", "template" ];

  connect() {
    // this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    this.wrapperClass = "nested-fields"
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
    // SelectTwo.init();
  }

  remove_association(event) {
    event.preventDefault()
    let wrapper = event.target.closest("." + this.wrapperClass)
    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      // wrapper.style.opacity = 0.1
      wrapper.classList.add("deleting")
      // var elements = wrapper.querySelectorAll("a, span")

      // for(var i=0; i<elements.length; i++){
      //   elements[i].remove();
      // }
    }
  }

  restore_association(event) {
    event.preventDefault()
    let wrapper = event.target.closest("." + this.wrapperClass)
    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 0
      // wrapper.style.opacity = 0.1
      wrapper.classList.remove("deleting")
      // var elements = wrapper.querySelectorAll("a, span")

      // for(var i=0; i<elements.length; i++){
      //   elements[i].remove();
      // }
    }
  }
}


