import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = { url: String }
  static targets = [ "position"]
  
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this),
      handle: ".sort-handle"
    })
  }

  end(event) {
 
    let p = 1
    for (const position of this.positionTargets){
      position.value = p
      p += 1
    }
  }
}