import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["menu"]

  connect() {
  }

  show(e){
    e.preventDefault
    this.menuTarget.classList.add("visible")
  }

  close(e){
    e.preventDefault
    this.menuTarget.classList.remove("visible")
  }




}