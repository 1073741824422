import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["popup", "backdrop"]

  connect() {
  }

  close(e) {
    e.preventDefault()
    this.popupTarget.classList.add("hide")
    this.backdropTarget.classList.add("hide")


  }


}